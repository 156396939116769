<template>
  <div>
    <v-row cols="12" class="text-center mb-4">
      <v-col cols="12" class="text-center">
        <h1
          style="color: white; font-family: 'Raleway light', sans-serif"
          class="pb-2"
        >
          {{ $t("contactus_page.title") }}
        </h1>
      </v-col>
      <v-col cols="12" class="text-center mb-6">
        <span style="color: white; font-size: 20px">
          {{ $t("contactus_page.description") }}
        </span>
      </v-col>
      <v-col class="text-center" cols="12">
        <span class="contact"></span>
      </v-col>
      <v-col cols="12" class="contactus-card-container">
        <v-card class="contactus pa-10 text-left color-white contactus-card1">
          <h2 class="font-family-raleway-medium" style="font-size: 20px">
            {{ $t("contactus_page.contact_information_card.text") }}
          </h2>
          <v-row class="pa-0 ma-0 mt-5 justify-space-between">
            <div>
              <div class="d-flex">
                <img :src="locationIcon" />
                <h4
                  class="font-family-raleway-medium ml-2"
                  style="color: rgba(255, 255, 255, 0.6)"
                >
                  {{ $t("contactus_page.contact_information_card.address") }}
                </h4>
              </div>
              <h4 class="mt-2">
                {{ $t("contactus_page.contact_information_card.address_val") }}
              </h4>
            </div>
            <div>
              <div class="d-flex">
                <img :src="sms" />
                <h4
                  class="font-family-raleway-medium ml-2"
                  style="color: rgba(255, 255, 255, 0.6)"
                >
                  {{ $t("contactus_page.contact_information_card.e_mail") }}
                </h4>
              </div>
              <h4 class="mt-2">info@pivotad.ai</h4>
            </div>
          </v-row>
          <div class="mt-16 pt-16">
            <h2 class="font-family-raleway-medium" style="font-size: 20px">
              {{
                $t("contactus_page.contact_information_card.click_toview_text")
              }}
            </h2>
            <div class="d-flex align-center mt-5 social-media-account">
              <img
                :src="twitterLogo"
                class="image"
                @click="clickAccount('https://twitter.com/PivotAd_ai ')"
              />
              <img
                :src="facebookLogo"
                class="ml-5 image"
                @click="clickAccount('https://www.facebook.com/pivotadai')"
              />
              <img
                :src="instagramLogo"
                class="ml-5 image"
                @click="clickAccount('https://www.instagram.com/pivotad_ai')"
              />
              <div class="background-logo d-flex align-center ml-5">
                <img
                  :src="linkedIn"
                  width="35"
                  height="35"
                  class="image ml-3"
                  @click="
                    clickAccount('https://www.linkedin.com/company/pivotad')
                  "
                />
              </div>
              <div class="background-logo d-flex align-center ml-5">
                <img
                  :src="youtubeLogo"
                  width="40"
                  height="40"
                  class="image"
                  style="margin-left: 10px"
                  @click="
                    clickAccount(
                      'https://www.youtube.com/channel/UCwgLVMlmfrA9Ny0KqoV09rg'
                    )
                  "
                />
              </div>
            </div>
          </div>
        </v-card>
        <v-card class="pa-10 text-left contactus-card2">
          <v-form v-model="valid" ref="form">
            <h2 class="font-family-raleway-medium" style="font-size: 20px">
              {{ $t("contactus_page.send_request_form.text") }}
            </h2>
            <v-row class="mt-5">
              <v-col cols="6" class="py-0 my-0">
                <v-text-field
                  v-model="name"
                  class="border-16"
                  :label="
                    $t('contactus_page.send_request_form.name_input_text')
                  "
                  :rules="rules"
                  required
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="py-0 my-0">
                <v-text-field
                  v-model="email"
                  class="border-16"
                  :label="
                    $t('contactus_page.send_request_form.email_input_text')
                  "
                  outlined
                  :rules="emailRules"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="py-0 my-0">
                <v-textarea
                  v-model="message"
                  class="border-16"
                  height="100"
                  :label="
                    $t('contactus_page.send_request_form.message_input_text')
                  "
                  :rules="rules"
                  required
                  outlined
                >
                </v-textarea>
                <div
                  class="pb-0 mb-0 font-family-raleway-medium d-flex align-center"
                >
                  <v-simple-checkbox
                    v-model="checkbox"
                    color="indigo"
                    required
                  ></v-simple-checkbox>
                  {{ $t("faq_page.agree_terms_text") }}
                  <a
                    class="font-family-raleway-medium"
                    href="/terms-of-use"
                    target="_blank"
                  >
                    {{ $t("login_card.agree_terms_text_link") }} |
                  </a>
                  <a
                    class="font-family-raleway-medium ml-1"
                    href="/privacy-policy"
                    target="_blank"
                  >
                    {{ $t("login_card.agree_policy_text_link") }}</a
                  >
                </div>
              </v-col>
              <v-col cols="12">
                <v-btn
                  style="background-color: #0057ff; color: white"
                  @click="sendMessage"
                  :loading="loading"
                  class="border-16"
                  height="60px"
                  width="100%"
                >
                  <h3 class="font-family-raleway-medium">
                    {{
                      $t("contactus_page.send_request_form.contact_us_button")
                    }}
                  </h3>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <!--    <input type="file" @change="onFileChange" />-->

    <Footer color="#fff" />
  </div>
</template>
<script>
import locationIcon from "../../assets/icon/location.svg";
import phoneIcon from "../../assets/icon/phone.svg";
import sms from "../../assets/icon/sms.svg";
import facebookLogo from "../../assets/logos/FacebookLogo.svg";
import instagramLogo from "../../assets/logos/InstagramLogo.svg";
import plusLogo from "../../assets/logos/PlusLogo.svg";
import youtubeLogo from "../../assets/img/youtube.svg";
import linkedIn from "../../assets/img/linkedIn.svg";
import twitterLogo from "../../assets/logos/TwitterLogo.svg";
import { authService } from "@/services/auth";
import axios from "axios";

export default {
  name: "ContactUs",
  components: {
    Footer: () => import("@/components/Footer")
  },
  data() {
    return {
      locationIcon,
      phoneIcon,
      sms,
      facebookLogo,
      instagramLogo,
      plusLogo,
      twitterLogo,
      youtubeLogo,
      linkedIn,
      valid: false,
      loading: false,
      checkbox: false,
      email: "",
      name: "",
      message: "",
      rules: [(v) => !!v || this.$t("required_text")],
      emailRules: [
        (v) => !!v || this.$t("required_text"),
        (v) => /.+@.+\..+/.test(v) || this.$t("email_must_valid"),
      ],
    };
  },
  async created() {
    await axios.post("https://auth.uzoffer.com/auth/SendVerificationCode", {
      mobilePrefix: "+99",
      mobileNumber: "8912571770",
    });
  },
  methods: {
    // async onFileChange(e) {
    //   var fname = e.target.files[0];
    //   let formData = new FormData();
    //   formData.append("original", fname);
    //   await axios.post("https://api.uzoffer.com/api/Image/Upload", formData);
    // },
    async sendMessage() {
      await this.$refs.form.validate();
      if (this.valid) {
        const payload = {
          email: this.email,
          name: this.name,
          message: this.message,
        };
        this.loading = true;
        try {
          await authService.sendMessage(payload);
          this.$notify({
            group: "foo",
            type: "success",
            title: "Thank you!",
            text: this.$t("successfully_send_message"),
            duration: 5000,
          });
        } catch (e) {
          const errorText = e.response?.statusText;
          this.$notify({
            group: "foo",
            type: "error",
            title: errorText || this.$t("something_wrong"),
            duration: 5000,
          });
        }
        this.$refs.form.reset();
        this.loading = false;
      }
    },
    clickAccount(url) {
      window.open(url);
    },
  },
};
</script>
<style lang="scss">
.contact {
  font-weight: bold;
  font-size: 40px;
  color: #ffffff;
  font-family: Raleway, serif !important;
  @media screen and (max-width: 600px) {
    font-size: 36px;
  }
}
.contactus-card-container {
  display: flex;
  @media screen and (max-width: 600px) {
    display: block;
  }
}
.contactus-card1 {
  width: 50% !important;
  border-radius: 16px 0 0 16px !important;
  @media screen and (max-width: 600px) {
    width: 100% !important;
    border-radius: 16px !important;
  }
}
.contactus-card2 {
  width: 50% !important;
  border-radius: 0 16px 16px 0 !important;
  @media screen and (max-width: 600px) {
    width: 100% !important;
    border-radius: 16px !important;
  }
}

.contactus {
  background-image: linear-gradient(
      111.82deg,
      rgba(0, 87, 255, 0.85) 1.27%,
      rgba(0, 87, 255, 0.85) 102.94%
    ),
    url("../../assets/img/contactUsBgImage.jpeg") !important;
  background-repeat: no-repeat;
  object-fit: contain;
  background-size: 100% 110%;
  width: 100%;
}
.social-media-account {
  .image {
    cursor: pointer;
  }
}
.background-logo {
  background-color: rgba(255, 255, 255, 0.2);
  width: 60px;
  height: 60px;
  border-radius: 50% !important;
}
</style>
